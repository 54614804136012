.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
@font-face {
  font-family: "TTNormsRegular";
  src: url("/extras/fonts/TT_Norms_Pro_Regular.woff2") format("woff2"), url("/extras/fonts/TT_Norms_Pro_Regular.woff") format("woff");
}
@font-face {
  font-family: "TTNormsBold";
  src: url("/extras/fonts/TT_Norms_Pro_Bold.woff2") format("woff2"), url("/extras/fonts/TT_Norms_Pro_Bold.woff") format("woff");
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
  font-size: 100%;
}
*::selection {
  background: #A5896E;
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: normal;
  font-family: 'TTNormsBold', sans-serif;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 18px;
  font-family: 'TTNormsRegular', sans-serif;
  line-height: 1.44444444;
}
.unit caption {
  display: none;
}
.flag {
  background: #A5896E;
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.area {
  float: left;
  width: 100%;
}
#view .area:empty {
  display: none;
}
.unit {
  float: left;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
}
@media (max-width: 1023px) {
  .unit {
    margin-top: 15px;
  }
}
@media (max-width: 767px) {
  .unit {
    margin-top: 15px;
  }
}
@media (max-width: 1023px) {
  .unit {
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .unit {
    margin-bottom: 15px;
  }
}
.unit .head,
.unit .body,
.unit .foot {
  float: left;
  width: 100%;
}
.unit.fold div.less,
.unit.fold div.more {
  float: left;
  width: 100%;
}
.unit.fold .ctrl {
  float: left;
  width: 100%;
}
.part {
  float: left;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
}
@media (max-width: 1023px) {
  .part {
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .part {
    margin-top: 10px;
  }
}
@media (max-width: 1023px) {
  .part {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .part {
    margin-bottom: 10px;
  }
}
.pict a {
  float: left;
  width: 100%;
}
.pict img {
  width: 100%;
}
.pict img:not(.svg) {
  width: auto;
  max-width: 100% !important;
}
#root#root#root img.zoom {
  display: none !important;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
}
#expo .cb-image-figure {
  height: 100%;
}
#expo .cb-image-container {
  height: 100% !important;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  background: none !important;
}
div.load a.load {
  display: inline-block;
  padding-left: 26px;
  background-size: 16px 16px;
  background-position: 0 5px;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-download-black.svg);
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding-top: 5px;
  padding-bottom: 5px;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid #A5896E;
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: normal;
  font-family: 'TTNormsBold', sans-serif;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.cb-service {
  float: left;
  width: 100%;
  margin: 5px 0;
}
.cb-service:first-child {
  margin-top: 0;
}
.cb-service:last-child {
  margin-bottom: 0;
}
.cb-youtube iframe,
.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 15px;
  position: relative;
}
.list--bullet li:before {
  content: '»';
  position: absolute;
  left: 0;
  top: 0;
}
body.c1 .section.even .list--bullet li:before {
  color: #FFFFFF;
}
body.c2 .section.odd .list--bullet li:before {
  color: #FFFFFF;
}
.section--six .list--bullet li {
  font-family: Georgia, Times, 'Times New Roman', serif;
  font-style: italic;
}
.section--six .list--bullet li:before {
  color: #FFFFFF;
}
.list--numbered {
  counter-reset: numberedList;
}
.list--numbered li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--numbered li:before {
  content: counter(numberedList) ".";
  counter-increment: numberedList;
  position: absolute;
  left: 0;
  top: 0;
}
#expo {
  float: left;
  width: 100%;
  position: relative;
}
#slides {
  float: left;
  width: 100%;
  height: 0 !important;
  max-width: 100% !important;
  max-height: 100% !important;
  padding-bottom: 100vh;
  position: relative;
}
@media (max-width: 1023px) {
  #slides {
    padding-bottom: 66.40625%;
  }
}
@media (max-width: 1023px) {
  #slides {
    padding-bottom: 89.84375%;
  }
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
#expo div.link {
  display: none;
  float: left;
  width: 100%;
  height: 0;
}
#expo a.link {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 30px;
  height: 50px;
  transform: translateY(-50%);
  background: #A5896E;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#expo a.link.prev {
  left: 20px;
}
#expo a.link.next {
  right: 20px;
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: block;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: #A5896E;
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'TTNormsRegular', sans-serif;
  line-height: 1.42857143;
  color: #000;
}
@media (max-width: 767px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 767px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 767px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: #A5896E;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: #A5896E;
  font-size: 16px;
  font-weight: normal;
  font-family: 'TTNormsBold', sans-serif;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  background-color: transparent;
  border: 1px solid #A5896E;
  line-height: 1.44444444;
  cursor: pointer;
  color: #A5896E;
  padding: 22px 40px;
  min-width: 100%;
  max-width: 600px;
  font-family: Georgia, Times, 'Times New Roman', serif;
  font-style: italic;
  font-size: 18px;
  line-height: 1.55555556;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  line-height: 1.42857143;
}
#disp .foot input:hover,
#disp .foot a:hover,
#disp .foot input:focus,
#disp .foot a:focus {
  color: #FFFFFF;
  background-color: #A5896E;
}
#disp .foot input:active,
#disp .foot a:active {
  background-color: #8a7056;
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 767px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 767px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 767px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input.radio,
#disp input.checkbox {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  left: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1023px) {
  .cb-zoom-indicator {
    left: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 767px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'TTNormsRegular', sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
}
@media (max-width: 767px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit.form form {
  float: left;
  width: 100%;
}
.cb-form-required {
  float: left;
  width: 100%;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
@media (min-width: 768px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
}
@media (min-width: 768px) {
  .unit.form .tile .name {
    width: 30%;
    min-height: 40px;
    padding-top: 7px;
    padding-bottom: 7px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background: #F0F0F0;
  min-height: 40px;
  padding: 5px 10px;
  -webkit-appearance: none;
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  background-color: #e7bcbc;
}
.unit.form textarea {
  padding: 10px;
}
@media (min-width: 768px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 7px;
    padding-bottom: 7px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  margin-right: 6px;
  height: 26px;
  vertical-align: top;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile input {
    top: 7px;
  }
}
.unit.form select {
  float: left;
  width: 100%;
  font-family: 'TTNormsRegular', sans-serif;
  font-size: 18px;
  line-height: 1.44444444;
  background: #F0F0F0;
  height: 40px;
  border: 1px solid #000;
  padding: 5px;
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 768px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 40px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form input.submit {
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: transparent;
  border: 1px solid #A5896E;
  line-height: 1.44444444;
  cursor: pointer;
  color: #A5896E;
  padding: 22px 40px;
  min-width: 100%;
  max-width: 600px;
  font-family: Georgia, Times, 'Times New Roman', serif;
  font-style: italic;
  font-size: 18px;
  line-height: 1.55555556;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}
@media (max-width: 1023px) {
  .unit.form input.submit {
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .unit.form input.submit {
    margin-top: 10px;
  }
}
@media (max-width: 1023px) {
  .unit.form input.submit {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .unit.form input.submit {
    margin-bottom: 10px;
  }
}
.unit.form input.submit:hover,
.unit.form input.submit:focus {
  color: #FFFFFF;
  background-color: #A5896E;
}
.unit.form input.submit:active {
  background-color: #8a7056;
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: #000;
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e7bcbc;
  color: #ae2121;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
@media (max-width: 1023px) {
  .unit.form div.cb-form-sent {
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .unit.form div.cb-form-sent {
    margin-top: 10px;
  }
}
@media (max-width: 1023px) {
  .unit.form div.cb-form-sent {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .unit.form div.cb-form-sent {
    margin-bottom: 10px;
  }
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
  font-size: 14px;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  background-color: transparent;
  border: 1px solid #A5896E;
  line-height: 1.44444444;
  cursor: pointer;
  color: #A5896E;
  padding: 22px 40px;
  min-width: 100%;
  max-width: 600px;
  font-family: Georgia, Times, 'Times New Roman', serif;
  font-style: italic;
  font-size: 18px;
  line-height: 1.55555556;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}
.two-step-verification-container a:hover,
.two-step-verification-container a:focus {
  color: #FFFFFF;
  background-color: #A5896E;
}
.two-step-verification-container a:active {
  background-color: #8a7056;
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #A5896E;
  border-bottom: 1px solid #A5896E;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 10px;
  vertical-align: top;
}
.table th.init {
  padding-left: 0;
}
.table th.exit {
  padding-right: 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: normal;
  font-family: 'TTNormsBold', sans-serif;
}
.table td {
  padding: 5px 10px;
  vertical-align: top;
}
.table td.init {
  padding-left: 0;
}
.table td.exit {
  padding-right: 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: normal;
  font-family: 'TTNormsBold', sans-serif;
}
@media (max-width: 767px) {
  .part--table {
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .part--table::-webkit-scrollbar {
    display: none;
  }
  .part--table .table {
    white-space: nowrap;
    width: auto;
    min-width: 100%;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
#home {
  float: left;
  height: 32px;
  margin-top: 25px;
  margin-bottom: 17px;
}
@media (max-width: 1023px) {
  #home {
    margin-top: 20px;
  }
}
@media (max-width: 1023px) {
  body.cb-toggle-target-active #home {
    content: url('/images/logo-tollkirsch-neg.svg');
  }
}
.logo {
  width: auto;
  height: 100%;
}
#head {
  float: left;
  width: 100%;
}
.cb-layout2 #head {
  margin-top: 75px;
  text-align: center;
}
@media (max-width: 1023px) {
  .cb-layout2 #head {
    margin-top: 75px;
  }
}
@media (max-width: 767px) {
  .cb-layout2 #head {
    margin-top: 75px;
  }
}
.cb-layout1 #head {
  margin-bottom: -25px;
}
@media (max-width: 1023px) {
  .cb-layout1 #head {
    margin-bottom: -25px;
  }
}
@media (max-width: 767px) {
  .cb-layout1 #head {
    margin-bottom: -25px;
  }
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  font-family: 'TTNormsRegular', sans-serif;
  font-size: 18px;
  line-height: 1.44444444;
  color: #000;
  overflow: hidden;
  hyphens: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: calc(100% - 90px);
  width: 1192px;
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
@media (max-width: 767px) {
  .desk {
    max-width: calc(100% - 40px);
  }
}
.section--three .desk {
  width: 100%;
  max-width: 100%;
}
.section--header .desk {
  width: calc(100% - 90px);
}
@media (max-width: 767px) {
  .section--header .desk {
    width: 100%;
    max-width: calc(100% - 40px);
  }
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.section--header {
  position: fixed;
  top: 0;
  z-index: 2000;
  background-color: #FFFFFF;
}
.cb-scroll-triggered--active .section--header {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
}
body.cb-toggle-target-active .section--header {
  box-shadow: unset;
  background-color: transparent;
}
.headcontainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.mobinfos {
  padding: 0 5vw;
  box-sizing: border-box;
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}
main {
  float: left;
  width: 100%;
  position: relative;
  flex: 1 0 auto;
}
body.c1 .section.odd {
  background-color: #FFFFFF;
}
body.c1 .section.even {
  background-color: #000000;
}
body.c2 .section.odd {
  background-color: #000000;
}
body.c2 .section.even {
  background-color: #FFFFFF;
}
.section--multimood {
  min-height: 10vh;
}
.section--gold {
  background-color: #A5896E;
}
.section--six:after {
  content: '';
  width: 0;
  height: 0;
  border-left: 28px solid transparent;
  border-right: 28px solid transparent;
  border-top: 23px solid #000000;
  position: absolute;
  top: -1px;
  left: 50%;
  margin-left: -28px;
}
body.c2 .section--six:after {
  border-top: 23px solid #FFFFFF;
}
.section--zero {
  margin-bottom: 25px;
}
@media (max-width: 1023px) {
  .section--zero {
    margin-bottom: 25px;
  }
}
@media (max-width: 767px) {
  .section--zero {
    margin-bottom: 25px;
  }
}
.cb-layout3 .section--zero {
  margin-bottom: 0;
}
.section--zero .container {
  float: left;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: 80px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}
.section--zero .multimood {
  float: left;
  width: 100%;
}
.section--zero .content {
  float: left;
  width: 1192px;
  max-width: 90%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section--zero .areaNineteen {
  width: 200px;
}
@media (max-width: 767px) {
  .section--zero .areaNineteen {
    width: 150px;
  }
}
.downlink {
  pointer-events: none;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
  width: 15px;
  height: 215px;
  z-index: 20;
}
@media (max-width: 1023px) {
  .cb-layout2 .downlink {
    display: none;
  }
}
.downlink:after {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  right: 0;
  bottom: 0;
  height: 100%;
  width: 1px;
  background-color: #A5896E;
}
.downlink:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 15px;
  width: 15px;
  border-radius: 100%;
  background-color: #A5896E;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.section--footer {
  flex-shrink: 0;
  background-color: #000000;
}
.footarea {
  float: left;
  position: relative;
  width: 100% ;
  margin-top: 50px;
  margin-bottom: 25px;
}
.footpart {
  float: left;
  position: relative;
  width: 100% ;
  margin-top: 25px;
  margin-bottom: 25px;
}
.footpart.footpart--wide {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1023px) {
  .footpart.footpart--slim {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
.footlogo {
  width: 110px;
}
.vcard {
  float: left;
  color: #FFF;
}
.vcard address {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.section--footer .vcard address {
  flex-direction: column;
}
.vcard address .name {
  float: left;
  width: 100%;
  margin-bottom: 10px;
}
.vcard address .name h5 {
  font-weight: normal;
  font-family: 'TTNormsBold', sans-serif;
  font-size: 18px;
  line-height: 1.55555556;
}
@media (max-width: 767px) {
  .mobinfos .vcard address .name h5 {
    font-size: 14px;
    line-height: 1.42857143;
  }
}
.vcard .row {
  float: left;
  font-family: Georgia, Times, 'Times New Roman', serif;
  font-style: italic;
  color: #A5896E;
}
@media (max-width: 767px) {
  .mobinfos .vcard .row {
    font-size: 14px;
    line-height: 1.42857143;
  }
}
@media (max-width: 1023px) {
  .section--footer .vcard .row.row--right {
    margin-top: 25px;
  }
}
.mobinfos .vcard .row.row--right {
  margin-left: 25px;
}
.newsletter {
  float: left;
  width: 100%;
  font-family: Georgia, Times, 'Times New Roman', serif;
  font-style: italic;
  color: #A5896E;
  margin-bottom: 10px;
}
#mc_embed_signup {
  float: left;
  position: relative;
}
#mc_embed_signup .mc-field-group {
  display: flex;
  flex-direction: row;
  float: left;
}
#mc_embed_signup label {
  display: none;
}
#mc_embed_signup .email {
  padding: 12px 20px;
  background-color: rgba(255, 255, 255, 0.1);
}
#mc_embed_signup .email:focus {
  color: #FFF;
}
#mc_embed_signup .button {
  background-color: #A5896E;
  cursor: pointer;
  color: #FFF;
  padding: 12px 28px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
#social {
  float: left;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
#social .meta {
  float: left;
  width: 25px;
  height: 25px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  margin-right: 20px;
}
#social .meta:last-child {
  margin-right: 0;
}
#social .meta.service_facebook {
  background-image: url(/images/facebook.svg);
}
#social .meta.service_linkedin {
  background-image: url(/images/linkedin.svg);
}
#social .meta.service_youtube {
  background-image: url(/images/youtube.svg);
}
#social .meta.service_instagram {
  background-image: url(/images/instagram.svg);
}
#legal {
  float: left;
  color: #FFF;
}
#legal .meta {
  font-size: 14px;
  color: #FFF;
  line-height: 1.92857143;
  letter-spacing: 0.02em;
  margin: 0 5px;
}
#legal .meta:first-child {
  margin-left: 0;
}
#legal .meta:last-child {
  margin-right: 0;
}
a {
  color: #A5896E;
  text-decoration: none;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.section--gold a {
  color: #FFFFFF;
}
.section--gold a:hover,
.section--gold a:focus {
  color: #e6e6e6;
}
a:hover,
a:focus {
  color: #7a634c;
}
.part.link > a.open {
  background-color: transparent;
  border: 1px solid #A5896E;
  line-height: 1.44444444;
  cursor: pointer;
  color: #A5896E;
  padding: 22px 40px;
  min-width: 100%;
  max-width: 600px;
  font-family: Georgia, Times, 'Times New Roman', serif;
  font-style: italic;
  font-size: 18px;
  line-height: 1.55555556;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}
.part.link > a.open:hover,
.part.link > a.open:focus {
  color: #FFFFFF;
  background-color: #A5896E;
}
.part.link > a.open:active {
  background-color: #8a7056;
}
.section--gold .part.link > a.open {
  background-color: #FFFFFF;
  color: #A5896E;
}
.section--gold .part.link > a.open:hover,
.section--gold .part.link > a.open:focus {
  background-color: #e6e6e6;
}
body.c1 .section.even h3,
body.c1 .section.even h4,
body.c1 .section.even h5,
body.c1 .section.even p.norm,
body.c1 .section.even p.loud,
body.c1 .section.even p.skew,
body.c1 .section.even .table {
  color: #FFFFFF;
}
body.c2 .section.odd h3,
body.c2 .section.odd h4,
body.c2 .section.odd h5,
body.c2 .section.odd p.norm,
body.c2 .section.odd p.loud,
body.c2 .section.odd p.skew,
body.c2 .section.odd .table {
  color: #FFFFFF;
}
.section--six h3,
.section--six h4,
.section--six h5,
.section--six p.norm,
.section--six p.loud,
.section--six p.skew,
.section--six .table {
  color: #FFFFFF;
}
h1 {
  font-family: Georgia, Times, 'Times New Roman', serif;
  font-style: italic;
  color: #A5896E;
  font-size: 18px;
  line-height: 1.55555556;
  font-weight: normal;
}
h2 {
  font-family: Georgia, Times, 'Times New Roman', serif;
  font-style: italic;
  color: #A5896E;
  font-size: 18px;
  line-height: 1.55555556;
  font-weight: normal;
}
.section--zero h2 {
  letter-spacing: -0.005em;
  font-size: 65px;
  line-height: 1;
  font-weight: normal;
  font-family: 'TTNormsBold', sans-serif;
  color: #000000;
  font-style: normal;
}
body.c2 .section--zero h2 {
  color: #FFFFFF;
}
h3 {
  letter-spacing: -0.005em;
  font-size: 45px;
  line-height: 1;
  font-weight: normal;
  font-family: 'TTNormsBold', sans-serif;
}
@media (max-width: 1023px) {
  .section--seven h3 {
    font-size: 35px;
    line-height: 1;
  }
}
h4 {
  font-size: 35px;
  line-height: 1;
  font-weight: normal;
  font-family: 'TTNormsBold', sans-serif;
}
h4 strong {
  color: #A5896E;
}
h5 {
  font-size: 28px;
  line-height: 1;
  font-weight: normal;
  font-family: 'TTNormsBold', sans-serif;
}
h5 strong {
  color: #A5896E;
}
p.norm {
  letter-spacing: 0.005em;
}
p.loud {
  font-size: 22px;
  line-height: 1.36363636;
  letter-spacing: 0.005em;
}
p.skew {
  font-size: 24px;
  line-height: 1.41666667;
  font-family: Georgia, Times, 'Times New Roman', serif;
  font-style: italic;
}
.section--one .area {
  margin-top: 175px;
  margin-bottom: 175px;
}
@media (max-width: 1023px) {
  .section--one .area {
    margin-top: 175px;
  }
}
@media (max-width: 767px) {
  .section--one .area {
    margin-top: 175px;
  }
}
@media (max-width: 1023px) {
  .section--one .area {
    margin-bottom: 175px;
  }
}
@media (max-width: 767px) {
  .section--one .area {
    margin-bottom: 175px;
  }
}
@media (max-width: 1023px) {
  .section--one .area {
    margin-top: 75px;
    margin-bottom: 75px;
  }
}
@media (max-width: 1023px) and (max-width: 1023px) {
  .section--one .area {
    margin-top: 75px;
  }
}
@media (max-width: 1023px) and (max-width: 767px) {
  .section--one .area {
    margin-top: 75px;
  }
}
@media (max-width: 1023px) and (max-width: 1023px) {
  .section--one .area {
    margin-bottom: 75px;
  }
}
@media (max-width: 1023px) and (max-width: 767px) {
  .section--one .area {
    margin-bottom: 75px;
  }
}
.cb-layout2 .section--one .area.main {
  margin-top: 0;
}
.section--two .area {
  margin-top: 175px;
  margin-bottom: 175px;
}
@media (max-width: 1023px) {
  .section--two .area {
    margin-top: 175px;
  }
}
@media (max-width: 767px) {
  .section--two .area {
    margin-top: 175px;
  }
}
@media (max-width: 1023px) {
  .section--two .area {
    margin-bottom: 175px;
  }
}
@media (max-width: 767px) {
  .section--two .area {
    margin-bottom: 175px;
  }
}
@media (max-width: 1023px) {
  .section--two .area {
    margin-top: 75px;
    margin-bottom: 75px;
  }
}
@media (max-width: 1023px) and (max-width: 1023px) {
  .section--two .area {
    margin-top: 75px;
  }
}
@media (max-width: 1023px) and (max-width: 767px) {
  .section--two .area {
    margin-top: 75px;
  }
}
@media (max-width: 1023px) and (max-width: 1023px) {
  .section--two .area {
    margin-bottom: 75px;
  }
}
@media (max-width: 1023px) and (max-width: 767px) {
  .section--two .area {
    margin-bottom: 75px;
  }
}
.section--four .area {
  margin-bottom: 75px;
}
@media (max-width: 1023px) {
  .section--four .area {
    margin-bottom: 75px;
  }
}
@media (max-width: 767px) {
  .section--four .area {
    margin-bottom: 75px;
  }
}
.section--six .area {
  margin-top: 75px;
  margin-bottom: 75px;
}
@media (max-width: 1023px) {
  .section--six .area {
    margin-top: 75px;
  }
}
@media (max-width: 767px) {
  .section--six .area {
    margin-top: 75px;
  }
}
@media (max-width: 1023px) {
  .section--six .area {
    margin-bottom: 75px;
  }
}
@media (max-width: 767px) {
  .section--six .area {
    margin-bottom: 75px;
  }
}
.section--seven .area {
  margin-top: 175px;
  margin-bottom: 175px;
}
@media (max-width: 1023px) {
  .section--seven .area {
    margin-top: 175px;
  }
}
@media (max-width: 767px) {
  .section--seven .area {
    margin-top: 175px;
  }
}
@media (max-width: 1023px) {
  .section--seven .area {
    margin-bottom: 175px;
  }
}
@media (max-width: 767px) {
  .section--seven .area {
    margin-bottom: 175px;
  }
}
@media (max-width: 1023px) {
  .section--seven .area {
    margin-top: 75px;
    margin-bottom: 75px;
  }
}
@media (max-width: 1023px) and (max-width: 1023px) {
  .section--seven .area {
    margin-top: 75px;
  }
}
@media (max-width: 1023px) and (max-width: 767px) {
  .section--seven .area {
    margin-top: 75px;
  }
}
@media (max-width: 1023px) and (max-width: 1023px) {
  .section--seven .area {
    margin-bottom: 75px;
  }
}
@media (max-width: 1023px) and (max-width: 767px) {
  .section--seven .area {
    margin-bottom: 75px;
  }
}
#view.main--empty .section--main {
  display: none;
}
#view.areaSeveteen--empty .section--areaSeveteen {
  display: none;
}
#view.areaTen--empty .section--areaTen {
  display: none;
}
#view.areaEleven--empty .section--areaEleven {
  display: none;
}
#view.areaTwelve--empty .section--areaTwelve {
  display: none;
}
#view.areaThirteen--empty .section--areaThirteen {
  display: none;
}
#view.side--empty .section--side {
  display: none;
}
#view.areaFourteen--empty .section--areaFourteen {
  display: none;
}
#view.areaFifteen--empty .section--areaFifteen {
  display: none;
}
#view.areaSixteen--empty .section--areaSixteen {
  display: none;
}
#view.areaEightteen--empty .section--areaEightteen {
  display: none;
}
#view.areaSeveteen--empty .section--six:after {
  display: none;
}
.section--one .area .wide.pure .part {
  text-align: center;
}
.section--one .area .wide.pure .part .list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.section--one .area .wide.pure .part .list li {
  float: left;
}
@media (max-width: 767px) {
  .section--one .area .slim.seam {
    margin-top: 45px;
    margin-bottom: 45px;
  }
}
@media (max-width: 767px) and (max-width: 1023px) {
  .section--one .area .slim.seam {
    margin-top: 45px;
  }
}
@media (max-width: 767px) and (max-width: 767px) {
  .section--one .area .slim.seam {
    margin-top: 45px;
  }
}
@media (max-width: 767px) and (max-width: 1023px) {
  .section--one .area .slim.seam {
    margin-bottom: 45px;
  }
}
@media (max-width: 767px) and (max-width: 767px) {
  .section--one .area .slim.seam {
    margin-bottom: 45px;
  }
}
.section--one .area .slim:not(.flat) .part.text h2,
.section--one .area .slim:not(.flat) .part.text h3,
.section--one .area .slim:not(.flat) .part.text h4,
.section--one .area .slim:not(.flat) .part.text h5 {
  text-align: center;
}
.section--one .area .slim:not(.flat) .part.pict .cb-image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section--one .area .slim:not(.flat) .part.pict:first-child:after {
  content: '';
  width: 0;
  height: 0;
  border-left: 28px solid transparent;
  border-right: 28px solid transparent;
  border-bottom: 23px solid #FFFFFF;
  position: absolute;
  bottom: -1px;
  left: 50%;
  margin-left: -28px;
}
body.c1 .section--one.even .area .slim  .part.pict:after,
body.c2 .section--one.odd .area .slim  .part.pict:after {
  border-bottom: 23px solid #000000;
}
.section--two .area .seam .part.line {
  position: relative;
  min-height: 84px;
  margin-top: 20px;
  margin-bottom: 30px;
}
@media (max-width: 1023px) {
  .section--two .area .seam .part.line {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .section--two .area .seam .part.line {
    margin-top: 20px;
  }
}
@media (max-width: 1023px) {
  .section--two .area .seam .part.line {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .section--two .area .seam .part.line {
    margin-bottom: 30px;
  }
}
.section--two .area .seam .part.line hr {
  margin: 0;
  padding: 0;
  margin-top: 43px;
}
.section--two .area .seam .part.line:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  transform: rotate(45deg) translateX(-28px);
  transform-origin: 50% 50%;
  border: 1px solid #A5896E;
  background-color: #FFFFFF;
  border-left: 1px solid transparent;
  border-top: 1px solid transparent;
}
body.c1 .section--two.even .area .seam  .part.line:after,
body.c2 .section--two.odd .area .seam  .part.line:after {
  background-color: #000000;
}
@media (max-width: 1023px) {
  .section--two .area .unit .part.pict.tall:first-child img {
    max-width: 50px!important;
  }
}
.section--two .area .unit .part.pict.tall:first-child .cb-image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section--three .area .unit {
  margin: 0;
}
.section--three .area .unit .part {
  margin: 0;
}
.section--three .area .unit .pict * {
  height: 100%;
}
.section--three .area .unit .pict img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.section--four .area .unit {
  margin-top: 0;
  margin-bottom: 0;
}
.section--seven .area .unit .part {
  text-align: center;
}
.section--seven .area .unit .part.text .open {
  color: #A5896E;
  line-height: 1.2;
  text-decoration: underline;
  text-underline-position: under;
  -webkit-box-decoration-break: clone;
}
.section--seven.section--gold .area .unit .part.text .open {
  color: #FFFFFF;
}
#view .scroll-animated {
  opacity: 0;
  transform: translateY(10rem);
  transition: transform 0.5s 0s cubic-bezier(0.425, 0.125, 0.11, 1), opacity 0.5s 0s ease-in-out;
}
#view .scroll-animated.scroll-animated--active {
  opacity: 1;
  transform: translateY(0);
}
@media (max-width: 1023px) {
  .section--two .area .unit .part {
    text-align: center;
  }
}
@media (max-width: 1023px) {
  .section--six .area .unit .part {
    text-align: center;
  }
  .section--six .area .unit .part .list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .section--six .area .unit .part .list li {
    float: left;
  }
}
#edit .area:before {
  font-size: 16px;
  letter-spacing: 0.05em;
  text-decoration: uppercase;
  line-height: 1;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  color: #71caf3;
  font-weight: normal;
  font-family: 'TTNormsBold', sans-serif;
  z-index: 999;
}
#edit .section--slider .area:before {
  content: 'Slider Bereich';
}
#cb-cookie-warning {
  position: fixed;
  bottom: 40px;
  z-index: 1990;
  font-family: 'TTNormsRegular', sans-serif;
  background-color: #FAFAFA;
  font-size: 15px;
  line-height: 1.6;
  padding: 24px 25px;
  box-sizing: border-box;
  color: #323F36;
  right: 0;
  transform: translateX(-200%);
  width: 580px;
  max-width: calc(100% - 80px);
  border-radius: 4px;
  opacity: 0;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
}
.show-content #cb-cookie-warning {
  left: 40px;
  opacity: 1;
  transform: translateX(0);
  transition: all 0.6s 0.2s cubic-bezier(0.32, 1.16, 0.36, 1.18);
}
#cb-cookie-warning.cb-cookie-warning--hidden {
  display: none !important;
}
@media (max-width: 767px) {
  .show-content #cb-cookie-warning {
    left: 0;
    max-width: 100%;
  }
}
.cb-cookie-warning--container {
  float: left;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}
.cb-cookie-warning--text {
  float: left;
  width: 100%;
  padding-top: 44px;
  box-sizing: border-box;
  background-size: 23px;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url(/images/icon-cookie.svg);
  font-size: 14px;
  line-height: 1.4;
}
.cb-cookie-warning--text h3 {
  font-size: 16px;
  font-family: 'TTNormsRegular', sans-serif;
  font-weight: normal;
  font-family: 'TTNormsBold', sans-serif;
  margin-bottom: 12px;
}
.cb-cookie-warning--text a {
  text-decoration: underline;
}
.cb-cookie-warning--actions {
  margin-top: 16px;
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}
.cb-cookie-warning__button {
  float: left;
}
#cb-cookie-warning__button--accept {
  --btnPrimaryColor: #FFFFFF;
  --btnPrimaryBgColor: #A5896E;
  --btnPrimaryColorHover: #FFFFFF;
  --btnPrimaryBgColorHover: #A5896E;
  background-color: var(--btnPrimaryBgColor);
  line-height: 1.44444444;
  font-family: inherit;
  cursor: pointer;
  color: var(--btnPrimaryColor);
  padding: 8px 16px;
  box-sizing: border-box;
  appearance: none;
  border-radius: var(--borderRadius, 4px);
  text-align: center;
  text-decoration: none;
  border: none;
  display: inline-block;
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
}
@media (hover: hover) and (pointer: fine) {
  #cb-cookie-warning__button--accept:hover,
  #cb-cookie-warning__button--accept:focus {
    color: var(--btnPrimaryColorHover);
    background-color: var(--btnPrimaryBgColorHover);
  }
}
#cb-cookie-warning__button--decline {
  text-decoration: underline;
  text-underline-position: under;
  font-family: inherit;
  appearance: none;
  cursor: pointer;
}
@media (max-width: 1023px) {
  .navigation .navi {
    display: none;
  }
}
.togglenavigation {
  position: fixed;
  top: 20px;
  right: 45px;
  z-index: 2001;
  width: 40px;
  height: 17px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .togglenavigation {
    right: 20px;
  }
}
.tline {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 10px;
  height: 10px;
  border: 1px solid #A5896E;
  border-radius: 100%;
  box-sizing: border-box;
  transition: all 0.4s ease-in;
}
.tline--2 {
  left: 15px;
}
.tline--3 {
  left: 30px;
}
body.cb-toggle-target-active .tline--3 {
  left: 25px;
  height: 15px;
  width: 15px;
  transition-duration: 0.2s;
  transition-delay: 0.2s;
  transform-origin: center;
}
body.cb-toggle-target-active .tline--1 {
  left: 27px;
  opacity: 0;
}
body.cb-toggle-target-active .tline--2 {
  left: 27px;
  opacity: 0;
}
.mobile-navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1999;
  width: 100%;
  height: 100%;
  background-color: #000000;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-top: 80px;
  box-sizing: border-box;
  transform: translateY(-100%);
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navigation {
  transform: translateY(0);
}
.mobile-navi-animation {
  float: left;
  width: 100%;
  opacity: 0;
  transform: translateY(-100vh);
  transition: all 0s 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navi-animation {
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  transform: translateY(0);
  opacity: 1;
}
.mobile-navigation div.navi {
  float: left;
  width: 100%;
  display: block;
  position: relative;
}
.mobile-navigation div.navi > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item {
  float: left;
  width: 100%;
  position: relative;
}
.mobile-navigation div.navi > .item.item-empty > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item > .menu {
  display: block;
  color: #000;
}
.mobile-navigation div.navi > .item > .menu.path {
  color: #A5896E;
}
.mobile-navigation div.sub1 {
  box-sizing: border-box;
  padding: 0 5vw;
  margin: 10px 0 40px;
}
.mobile-navigation div.sub1 > .item {
  margin: 25px 0;
  display: flex;
  justify-content: center;
}
.mobile-navigation div.sub1 > .item.init {
  margin-top: 0;
}
.mobile-navigation div.sub1 > .item.exit {
  margin-bottom: 0;
}
.mobile-navigation div.sub1 > .item > .menu {
  float: left;
  padding-bottom: 5px;
  font-family: Georgia, Times, 'Times New Roman', serif;
  font-style: italic;
  color: #A5896E;
  font-size: 40px;
  line-height: 1.25;
  text-align: center;
  border-bottom: 4px solid transparent;
}
@media (max-width: 767px) {
  .mobile-navigation div.sub1 > .item > .menu {
    font-size: 26px;
    line-height: 1;
  }
}
.mobile-navigation div.sub1 > .item > .menu.path {
  border-color: #A5896E;
}
.mobile-navigation div.sub2 {
  display: none;
}
.mobile-navigation .cb-toggle {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  cursor: pointer;
  width: 36px;
  height: 24px;
  background-size: 14px 14px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-down-black.svg);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.mobile-navigation .cb-toggle.cb-toggle-active {
  transform: rotate(-180deg);
}
.cb-album {
  position: relative;
}
.cb-album .body-mobile {
  display: none !important;
}
.cb-album h2 {
  display: none;
}
.cb-album .body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cb-album .part {
  width: 100% !important;
  margin: 0 !important;
}
.cb-album .part.desc {
  display: none;
}
#root.ie11 .cb-album img {
  height: auto !important;
  max-height: 1000000000px !important;
}
.cb-album.cb-album .scan {
  margin-top: 40px !important;
}
.cb-album.cb-album .scan:first-child {
  margin-top: 0 !important;
  margin-bottom: 40px !important;
}
.cb-album .scan table,
.cb-album .scan tbody,
.cb-album .scan tr {
  float: left;
  display: block;
  width: 100%;
}
.cb-matrix .body {
  margin-top: -0.5%;
  margin-bottom: -0.5%;
}
.cb-matrix .part {
  margin-top: 0.5% !important;
  margin-bottom: 0.5% !important;
}
.cb-matrix .part.foto {
  height: auto !important;
  box-sizing: border-box;
}
.cb-matrix .part.foto a.foto,
.cb-matrix .part.foto span.foto {
  display: block;
  height: 0;
  padding-bottom: 60%;
  position: relative;
  overflow: hidden;
}
.cb-matrix .part.foto img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
  transition: transform 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-matrix .part.foto a.foto.zoom img {
  transform: scale(1.1);
}
.cb-matrix.cb-matrix-columns-1 .part.foto {
  width: 100% !important;
}
.cb-matrix.cb-matrix-columns-2 .part.foto {
  width: 49.5% !important;
}
.cb-matrix.cb-matrix-columns-3 .part.foto {
  width: 32.66666667% !important;
}
.cb-matrix.cb-matrix-columns-4 .part.foto {
  width: 24.25% !important;
}
.cb-matrix.cb-matrix-columns-5 .part.foto {
  width: 19.2% !important;
}
.cb-matrix.cb-matrix-columns-6 .part.foto {
  width: 15.83333333% !important;
}
.cb-matrix.cb-matrix-columns-7 .part.foto {
  width: 13.42857143% !important;
}
.cb-matrix.cb-matrix-columns-8 .part.foto {
  width: 11.625% !important;
}
.cb-matrix .scan tr {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.cb-matrix .scan td {
  float: left;
  display: block;
  margin: 0 10px;
  font-size: 16px;
  line-height: 1.25;
}
.cb-matrix .scan td:first-child {
  margin-left: 0;
}
.cb-matrix .scan td:last-child {
  margin-right: 0;
}
.cb-matrix .scan td.prev,
.cb-matrix .scan td.next {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 20px;
  height: 20px;
}
.cb-matrix .scan td.prev a,
.cb-matrix .scan td.next a {
  float: left;
  display: block;
  width: 100%;
  height: 100%;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-album-left.svg);
}
.cb-matrix .scan td.prev:empty,
.cb-matrix .scan td.next:empty {
  opacity: 0.5;
  pointer-events: none;
}
.cb-matrix .scan td.next a {
  background-image: url(/images/arrow-album-right.svg);
}
.cb-matrix .scan td a {
  color: #000;
}
.cb-matrix .scan td .same {
  color: #A5896E;
}
.cb-strips .part.foto {
  height: auto !important;
  max-height: 1000000000px !important;
}
.cb-strips-images {
  float: left;
  width: 100%;
  height: 0 !important;
  padding-bottom: 56.25%;
  position: relative;
}
.cb-strips-images a {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transform: scale(0.96);
  z-index: 2;
  transition: opacity 1s cubic-bezier(0.05, 0.8, 0.5, 1), transform 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  overflow: hidden;
}
.cb-strips-images a.is-active {
  z-index: 3;
  pointer-events: auto;
  opacity: 1;
  transform: scale(1);
}
.cb-strips-images img {
  opacity: 1 !important;
  display: block !important;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.cb-strips .scan tbody > tr {
  float: left;
  width: 100%;
  display: flex;
}
.cb-strips .scan tbody > tr > td {
  float: left;
  width: 100%;
  display: block;
}
.cb-strips .scan td.prev,
.cb-strips .scan td.next {
  margin-right: 1.25%;
  width: 6.25%;
}
.cb-strips .scan td.next {
  margin-right: 0;
  margin-left: 1.25%;
}
.cb-strips .scan div.prev,
.cb-strips .scan div.next {
  float: left;
  width: 100%;
  height: 100%;
}
.cb-strips .scan div.prev a,
.cb-strips .scan div.next a {
  float: left;
  display: block;
  width: 100%;
  height: 100%;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
  transition: background 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-strips .scan div.prev a:hover,
.cb-strips .scan div.next a:hover {
  background-position: 40% 50%;
}
.cb-strips .scan div.next a {
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.cb-strips .scan div.next a:hover {
  background-position: 60% 50%;
}
.cb-strips .scan td.cb-strips-thumbs {
  width: 85%;
}
.cb-strips-container {
  float: left;
  width: 100%;
}
.cb-strips-container .body {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.cb-strips-container .part.foto {
  position: relative;
}
.cb-strips-container .part.foto a.foto,
.cb-strips-container .part.foto span.foto {
  float: left;
  width: 100%;
  display: block;
  height: 0;
  position: relative;
  padding-bottom: 60%;
  opacity: 0.4;
  transition: opacity 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  overflow: hidden;
}
.cb-strips-container .part.foto a.foto:hover,
.cb-strips-container .part.foto span.foto:hover,
.cb-strips-container .part.foto a.foto.is-active,
.cb-strips-container .part.foto span.foto.is-active {
  opacity: 1;
}
.cb-strips-container .part.foto a.foto .cb-zoom-indicator,
.cb-strips-container .part.foto span.foto .cb-zoom-indicator {
  display: none;
}
.cb-strips-container .part.foto img {
  opacity: 1 !important;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
  transition: transform 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-strips-container .part.foto a.foto:hover img {
  transform: scale(1.2);
}
.cb-strips-4 .cb-strips-container .part.foto {
  width: 23.95833333% !important;
}
.cb-strips-6 .cb-strips-container .part.foto {
  width: 15.50925926% !important;
}
.cb-strips-8 .cb-strips-container .part.foto {
  width: 11.28472222% !important;
}
.cb-strips-10 .cb-strips-container .part.foto {
  width: 8.75% !important;
}
.cb-slideshow-images {
  float: left;
  width: 100%;
}
.cb-slideshow .part.foto,
.cb-slideshow .part.crossslideContainer {
  max-height: 1000000000px !important;
  max-width: 100% !important;
  height: 0 !important;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;
}
@media (max-width: 1023px) {
  .cb-slideshow .part.foto,
  .cb-slideshow .part.crossslideContainer {
    padding-bottom: 75.87890625%;
  }
}
@media (max-width: 767px) {
  .cb-slideshow .part.foto,
  .cb-slideshow .part.crossslideContainer {
    padding-bottom: 75.78125%;
  }
}
.cb-slideshow a.foto {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  z-index: 1;
  transition: opacity 1s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-slideshow a.foto.is-activated {
  z-index: 2;
  pointer-events: auto;
  opacity: 1;
}
.cb-slideshow img {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
  display: block !important;
  opacity: 1 !important;
  visibility: visible !important;
  border: none !important;
  z-index: 1;
}
.cb-slideshow img.is-activated {
  z-index: 2;
}
#view .cb-slideshow.cb-manual img {
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  transform: scale(1.05);
}
#view .cb-slideshow.cb-manual a.foto.is-activated img {
  transform: scale(1);
}
#view .cb-slideshow.cb-fading img {
  opacity: 0 !important;
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  transform: scale(1.05);
}
#view .cb-slideshow.cb-fading img.is-activated,
#view .cb-slideshow.cb-fading .cb-image-figure.is-activated img {
  opacity: 1 !important;
  transform: scale(1);
}
#view .cb-slideshow.cb-sliding img {
  opacity: 0 !important;
  width: 110% !important;
  max-width: 110% !important;
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  animation-name: SlideshowSlide;
  animation-duration: 12s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
#view .cb-slideshow.cb-sliding img.is-activated,
#view .cb-slideshow.cb-sliding .cb-image-figure.is-activated img {
  opacity: 1 !important;
}
@keyframes SlideshowSlide {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -10%;
  }
  100% {
    margin-left: 0;
  }
}
#view .cb-slideshow.cb-moving img {
  opacity: 0 !important;
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  animation-name: SlideshowMove;
  animation-duration: 16s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
#view .cb-slideshow.cb-moving img.is-activated,
#view .cb-slideshow.cb-moving .cb-image-figure.is-activated img {
  opacity: 1 !important;
}
@keyframes SlideshowMove {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.cb-slideshow .head {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 4;
  height: 0;
  width: 100%;
}
.cb-slideshow .ctrl {
  float: left;
  width: 100%;
}
.cb-slideshow .ctrl a {
  display: block;
  position: absolute;
  top: 0;
  right: 100px;
  transform: translateY(-100%);
  width: 100px;
  height: 100px;
  cursor: pointer;
  background-color: #FFFFFF;
  background-size: 25px 50px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-album-left.svg);
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
@media (max-width: 1023px) {
  .cb-slideshow .ctrl a {
    width: 50px;
    height: 50px;
    background-size: 12px 25px;
    right: 50px;
  }
}
.cb-slideshow .ctrl a:hover {
  background-color: rgba(255, 255, 255, 0.6);
}
.cb-slideshow .ctrl a.fade {
  opacity: 0.4;
  cursor: default;
}
.cb-slideshow .ctrl .next a {
  left: unset;
  right: 0;
  background-image: url(/images/arrow-album-right.svg);
}
.crossslide {
  float: left;
  width: 100%;
  max-width: 100% !important;
  position: static !important;
  padding: 0 !important;
}
.section--four .area {
  width: calc(100% + 20px);
  margin-left: -10px;
  display: flex;
  flex-wrap: wrap;
}
.section--four .area .unit {
  margin-left: 10px;
  margin-right: 10px;
}
.section--four .area .slim {
  width: calc(50% - 20px);
}
@media (max-width: 767px) {
  .section--four .area .slim.seam {
    width: 100%;
  }
  .section--four .area .slim.seam .body {
    width: calc(100% + 20px);
    margin-left: -10px;
  }
  .section--four .area .slim.seam .part {
    width: calc(50% - 20px);
    margin-left: 10px;
    margin-right: 10px;
  }
  .section--four .area .slim.flat {
    width: 100%;
  }
}
.section--slider .desk {
  width: 1192px;
  max-width: calc(100% - 90px);
  margin-top: 75px;
  margin-bottom: 75px;
}
@media (max-width: 1023px) {
  .section--slider .desk {
    margin-top: 75px;
  }
}
@media (max-width: 767px) {
  .section--slider .desk {
    margin-top: 75px;
  }
}
@media (max-width: 1023px) {
  .section--slider .desk {
    margin-bottom: 75px;
  }
}
@media (max-width: 767px) {
  .section--slider .desk {
    margin-bottom: 75px;
  }
}
.js-slider {
  float: left;
  width: 100%;
}
.js-slider__width {
  float: left;
  width: 100%;
}
#view .js-slider__width {
  overflow: hidden;
}
#edit .section--slider .area {
  display: flex;
  flex-wrap: wrap;
}
#view .section--slider .area {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
}
.section--slider .area .unit {
  min-height: 0;
  width: calc(100% - 20px);
  margin-left: 10px;
  margin-right: 10px;
}
.section--slider .area .unit .body {
  padding: 80px 100px;
  border: 1px solid #A5896E;
  box-sizing: border-box;
}
.section--slider .area .unit .body:after {
  content: '';
  position: absolute;
  top: -1px;
  left: 50%;
  width: 60px;
  height: 60px;
  transform: rotate(45deg) translateX(-42px);
  transform-origin: 50% 50%;
  border: 1px solid #A5896E;
  background-color: #FFFFFF;
  border-left: 1px solid transparent;
  border-top: 1px solid transparent;
}
.section--slider .area .unit .part {
  text-align: center;
}
.section--slider .area .unit .part p.skew {
  color: #A5896E;
}
.section--slider .area .unit .part.pict:first-child .svg {
  margin-bottom: 25px;
}
@media (max-width: 1023px) {
  .section--slider .area .unit .part.pict:first-child .svg {
    margin-bottom: 25px;
  }
}
@media (max-width: 767px) {
  .section--slider .area .unit .part.pict:first-child .svg {
    margin-bottom: 25px;
  }
}
@media (max-width: 767px) {
  .section--slider .area .unit .part.pict:first-child .svg {
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) and (max-width: 1023px) {
  .section--slider .area .unit .part.pict:first-child .svg {
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) and (max-width: 767px) {
  .section--slider .area .unit .part.pict:first-child .svg {
    margin-bottom: 15px;
  }
}
body.c1 .section--slider.even .area .unit  .body:after,
body.c2 .section--slider.odd .area .unit  .body:after {
  background-color: #000000;
}
#view .section--slider .area .unit {
  margin: 0 10px;
  width: 1192px;
  flex-shrink: 0;
  transition: all 0.8s ease-in-out;
  min-height: 300px;
}
#view .section--slider .area .unit .body {
  width: 1192px;
  min-height: 300px;
}
#view .section--slider .area .unit.unit--collapsed {
  width: 0;
  margin-left: 0;
  margin-right: 0;
}
.js-slider__link {
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 19px;
  height: 38px;
  right: 0;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-album-left.svg);
  cursor: pointer;
}
body.c1 .section--slider.even .js-slider__link,
body.c2 .section--slider.odd .js-slider__link {
  background-image: url(/images/arrow-album-left-white.svg);
}
.section--white .js-slider__link {
  filter: invert(1);
}
#edit .js-slider__link {
  display: none;
}
.no-slider .js-slider__link {
  display: none;
}
.js-slider__link--prev {
  right: 60px;
}
.js-slider__link--next {
  background-image: url(/images/arrow-album-right.svg);
}
body.c1 .section--slider.even .js-slider__link--next,
body.c2 .section--slider.odd .js-slider__link--next {
  background-image: url(/images/arrow-album-right-white.svg);
}
.js-slider__index {
  display: none;
  position: absolute;
  left: 0;
  bottom: 25px;
  z-index: 3;
}
.slide__index {
  float: left;
  margin: 0 4px;
  width: 10px;
  height: 10px;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.slide__index.slide__index--active {
  border: 1px solid #000000;
}
.section--black .slide__index {
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.section--black .slide__index.slide__index--active {
  border: 1px solid #FFFFFF;
}
.slide__index:first-child {
  margin-top: 0;
}
.slide__index:last-child {
  margin-bottom: 0;
}
@media (max-width: 1023px) {
  #view .section--slider .area .unit {
    margin: 0 10px;
    width: calc(100vw - 90px);
  }
  #view .section--slider .area .unit .body {
    width: calc(100vw - 90px);
  }
}
@media (max-width: 767px) {
  .section--slider .desk {
    width: 100%;
    max-width: calc(100vw - 40px);
  }
  .section--slider .area .unit {
    min-height: 0;
  }
  .section--slider .area .unit .body {
    min-height: 0;
    padding: 40px 25px 30px;
  }
  #view .section--slider .area .unit {
    width: calc(100vw - 40px);
  }
  #view .section--slider .area .unit .body {
    width: calc(100vw - 40px);
  }
}
/*# sourceMappingURL=./screen-small.css.map */